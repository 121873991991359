interface DateI {
  day: string
  month: number
  year: number
}
export function getDate(date: string): DateI | null {
  if (!date) return null
  const publishDate: Date = new Date(date)

  if (isNaN(publishDate.getTime())) {
    return null
  }

  const day: string = String(publishDate.getDate()).padStart(2, '0')
  const month: number = publishDate.getMonth() + 1
  const year: number = publishDate.getFullYear()

  return { day, month, year }
}

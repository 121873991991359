import { toInteger } from 'lodash-es'
import fetchGql from '@/utils/fetchGql'
import type IBanner from '@/types/Storyblok/General/Banner'
import { getDate } from '@/utils/getDate'
import { isEn } from '@/utils/helpers'

const query = gql`
  query SinglepressreleaseItems($language: String, $perPage: Int, $page: Int, $slug: String, $filter: JsonScalar) {
    SinglepressreleaseItems(
      starts_with: $language
      per_page: $perPage
      page: $page
      sort_by: "content.publishDate:desc"
      by_slugs: $slug
      filter_query: $filter
    ) {
      total
      items {
        content {
          title
          lead
          publishDate
        }
        full_slug
      }
    }
  }
`

const parseData = (data: any, language: string) => {
  const bannerData: IBanner = ref([])

  data.forEach((banner: any) => {
    const bannerDate = getDate(banner.content.publishDate)
    const slug = isEn(language)
      ? banner.full_slug.replace('press-releases/', '')
      : banner.full_slug.replace(`${language}/press-releases/`, '')

    bannerData.value.push({
      title: banner?.content?.title || '',
      text: banner?.content?.lead,
      day: bannerDate?.day,
      month: bannerDate?.month,
      year: bannerDate?.year,
      slug
    })
  })
  return bannerData.value
}

export const useFetchPressReleases = () => {
  const fetchPressReleases = (language: string, perPage: number, page: number, year: number) => {
    const variables = {
      language: isEn(language) ? '' : `${language}/*`,
      perPage: toInteger(perPage),
      page: toInteger(page),
      slug: `press-releases/${year}/*`,
      filter: { availableLanguages: { in_array: isEn(language) ? 'default' : language } }
    }

    return fetchGql(query, variables).then((response: any) => ({
      total: response.SinglepressreleaseItems.total,
      items: parseData(response.SinglepressreleaseItems.items, language)
    }))
  }

  return { fetchPressReleases }
}
